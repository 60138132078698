import React, { Component } from 'react'
import './App.css'
import Details from './components/Details'
export default class App extends Component {
  

  render() {
    return (
      <div className='py-3'>
        <Details />
      </div>
    )
  }
}
