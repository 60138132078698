import React, { Component } from 'react'
import Spinner from './Spinner';

export default class Details extends Component {
    constructor() {
        super();
        this.state =
        {
            data: [],
            loading: true,
        }

    }
    async componentDidMount() {
        let req = await fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=5ea8894476464c108c7f3fa655e7f97a");
        let data1 = await req.json();
        this.setState({ data: data1, loading: false });
    }
    render() {
        return (
            <div>
                <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl  py-3 text-center font-bold">Your Details Based On Your IP Address:</p>
                {this.state.loading ?
                    <div className="flex justify-center items-center py-20">
                        <Spinner />
                    </div>
                    :
                    <div className='my-4 mx-10 flex justify-center'>
                        <table className='border-2 border-gray-400 w-3/4'>
                            <tbody>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>IP Address</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.ip_address}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>City</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.city}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Region</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.region}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Region ISO Code</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.region_iso_code}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Postal Code</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.postal_code}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Country</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.country}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Country Code</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.country_code}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Continent</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.continent}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Continent Code</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.continent_code}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Longitude</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.longitude}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Latitude</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.latitude}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2' colSpan={2}>Timezone</th>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Name</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.timezone.name}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Abbreviation</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.timezone.abbreviation}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>GMT Offset</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.timezone.gmt_offset}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Current Time</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.timezone.current_time}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2' colSpan={2}>Currency</th>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Currency Name</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.currency.currency_name}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Currency Code</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.currency.currency_code}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2' colSpan={2}>Connection Details</th>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Autonomous System Number</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.connection.autonomous_system_number}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Autonomous System Organization</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.connection.autonomous_system_organization}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Connection Type</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.connection.connection_type}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>ISP Name</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.connection.isp_name}</td>
                                </tr>
                                <tr className='border-2 border-gray-400 border-collapse'>
                                    <th className='border-2 border-gray-400 border-collapse px-3 py-2'>Organization Name</th>
                                    <td className='border-2 border-gray-400 border-collapse px-3 py-2'>{this.state.data.connection.organization_name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        )
    }
}
